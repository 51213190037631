<template>
    <section class="dosiin_tab-new-brand-new" v-for="i in 10" :key="i">
        <div class="tab-new-brand-item_brand">
            <div class="no-image">
                <Skeletor class="search-item_logo" width="40" height="40" circle/>
            </div>
            
            <dl class="new-brand_info">
                <Skeletor class="search-item_title truncate" width="30%"  />
            </dl>
        </div>
        <section class="banner-intro-section">
            <div class="banner">
                <Skeletor class="styled-img" height="210"/>
            </div>
        </section>
        <section class="page-section">
            <div class="list-item product-list-item" v-for="p in 3" :key="p">
                <div class="no-image">
                    <Skeletor class="cm-image dosiin_w-100 ls-is-cached lazyloaded"  width="68" height="68" />
                </div>
                <dl class="list-item_summary">
                    <dt><Skeletor class="list-item_brand" width="40%" /></dt>
                    <dd class="list-item_name"><Skeletor class="list-item_brand" width="100%" /></dd>
                </dl>
            </div>
            
            <div class="seemore-btn">
                <Skeletor class="primary-button button" width="100%" pill/>
            </div>
        </section>
    </section>
</template>